define(['app'], function(app) {

  

  var productListDescription = function() {
    var self = this;
    self.app = app;

    var _config = {
      listDescription: '[data-js-element=listDescription]',
      readMore: '[data-js-element=readMore]',
    };

    var _init = function(element) {
      self.element = element;
      self.element = element;
      self.listDescription = self.element.querySelector(self.config.listDescription);
      self.readMore = self.element.querySelector(self.config.readMore);
      self.listDescriptionExpandedClass = self.listDescription.dataset.jsExpanded;
      self.showReadMoreClass = self.readMore.dataset.jsShow;
      self.readLessText = self.readMore.dataset.readLess;
      self.readMoreText = self.readMore.dataset.readMore;

      self.checkDescriptionContent();
      _bind();
      return self;
    };

    var _bind = function() {
      self.readMore.addEventListener('click', _toggleDescription);
    };

    var _checkDescriptionContent = function() {
      var description = self.listDescription.textContent;

      if (description && description !== ' ') {
        self.showReadMore();
      }
    };

    var _showReadMore = function() {
      app.element.addClass(self.showReadMoreClass, self.readMore);
    };

    var _toggleDescription = function() {
      if (self.readMore.dataset.state === 'closed') {
        self.openDescription();
      } else {
        self.closeDescription();
      }
    };

    var _openDescription = function() {
      app.element.addClass(self.listDescriptionExpandedClass, self.listDescription);
      self.readMore.textContent = self.readLessText;
      self.readMore.dataset.state = 'open';
    };

    var _closeDescription = function() {
      app.element.removeClass(self.listDescriptionExpandedClass, self.listDescription);
      self.readMore.textContent = self.readMoreText;
      self.readMore.dataset.state = 'closed';
    };

    self.config = _config;
    self.init = _init;
    self.checkDescriptionContent = _checkDescriptionContent;
    self.openDescription = _openDescription;
    self.showReadMore = _showReadMore;
    self.closeDescription = _closeDescription;
    self.toggleDescription = _toggleDescription;
  };

  return productListDescription;
});
